import React from "react"
import { Link } from "gatsby"
import iotimage from "../../assets/images/banners/service-images/iot.png"

import consulting from '../../assets/images/banners/icon-pack/consultation.png'
import services from '../../assets/images/banners/icon-pack/customer-service.png'
import product from '../../assets/images/banners/icon-pack/product.png'

const InternetOfThning = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img internet"
          data-bg="assets/images/banners/service-banners/iot.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">Internet Of Things</h1>
                  <h5 className="font-weight--normal text-white">
                    With our IoT expertise, we provide cutting-edge engineering
                    and enterprise-wide IoT solutions tailored to the needs of
                    our clients.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== About Company Area Start ==========-->
            <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">Our IT Service</h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                  <h2 className="heading">Internet Of Things<span className="text-color-primary"> (IoT)</span></h2>
                  Aamanto offers a range of services and innovative
                  consumer electronics startups to develop IoT
                  solutions. We guide our clients in the development of
                  smart devices, the conversion of analogue products to
                  digital using sensors, firmware, and IoT platforms,
                  and the creation of custom IoT infrastructure
                  (embedded, web, and mobile). We assist clients in
                  configuring IoT devices, managing cyber-physical
                  systems both on-site and remotely, analyzing sensor
                  data with business intelligence tools, and presenting
                  it through visualizations.

                  <h2 className="heading pt-4">Our Expertise in IoT Services</h2>
                  Aamanto IoT testing approach emphasizes end-to-end
                  automation to ensure optimum test coverage and cost
                  savings for large IoT infrastructure and operations.
                  We are a trusted partner for IoT testing services for
                  worldwide product firms because of our competence with
                  new-age tools and technologies, cloud collaborations,
                  and operational technology understanding.
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={iotimage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============-->

            <!--=========== About Company Area End ==========-->
            
              <!--===========  feature-icon-wrapper  Start =============--> */}
        <div className="feature-icon-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">Prominent Internet Of Things (IoT) Services</h2>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12">
                <div className="feature-list__three">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-shield-check"></i>
                              </div>
                              <h5 className="heading">Consulting</h5>
                            </div>
                            <div className="content">
                              <div className="text">
                                IoT programme blueprinting tailored to the
                                industry and commercial use case. The business
                                model transformation, solution designs
                                (microservices-based) for device management, and
                                operations strategy roadmap and consultation.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-lock-alt"></i>
                              </div>
                              <h5 className="heading">Services Management</h5>
                            </div>
                            <div className="content">
                              <div className="text">
                                IoT cybersecurity operations for connected
                                device networks – VAPT, code analysis and
                                DevSecOps.CloudOps, DevOps, Containerization,
                                and Kubernetes are all used to improve L2 and L3
                                support SLA metrics.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6" style={{ margin: "0px auto" }}>
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-magnet"></i>
                              </div>
                              <h5 className="heading">
                                Product Engineering from the Edge to the Cloud
                              </h5>
                            </div>
                            <div className="content">
                              <div className="text">
                                Sensors, connectivity, connectivity protocols,
                                edge analytics, and interoperability. IoT
                                platform, gateway framework, data processing IoT
                                security, communication protocols, and message
                                brokers. Device lifecycle management, cloud
                                integration, and application and analytics.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div class="v1-box-2-parent">
              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={consulting} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Consulting</h5>
                            <div className="v1-text-2">
                              IoT programme blueprinting tailored to the industry and commercial use case. The business model transformation, solution designs (microservices-based) for device management, and operations strategy roadmap and consultation.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={services} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Services Management</h5>
                            <div className="v1-text-2">
                              IoT cybersecurity operations for connected device networks – VAPT, code analysis and DevSecOps.CloudOps, DevOps, Containerization, and Kubernetes are all used to improve L2 and L3 support SLA metrics.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={product} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Product Engineering from the Edge to the Cloud</h5>
                            <div className="v1-text-2">
                              Sensors, connectivity, connectivity protocols, edge analytics, and interoperability. IoT platform, gateway framework, data processing IoT security, communication protocols, and message brokers. Device lifecycle management, cloud integration, and application and analytics.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

            </div>


          </div>
        </div>
        {/* <!--===========  feature-icon-wrapper  End =============--> */}
      </div>
    </div>
  )
}
export default InternetOfThning
