import * as React from "react"
import Layout from "../components/layout"
import InternetOfThing from "../components/Services/InternetOfThing"






const internet = () => {
  return (
  <Layout>
   <InternetOfThing/>
  </Layout>
)
};
export default internet;
